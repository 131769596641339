import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout.js";
import NewsItem from "../components/news-item";
import "../styles/news.css";

export default function NewsPage() {
  const data = useStaticQuery(graphql`
    query NewsPage {
      allSanityNewsItem(sort: { fields: _createdAt, order: DESC }) {
        nodes {
          _id
          title
          _rawBody
          callToAction {
            href
            linkText
          }
        }
      }
      allSanityGroupEvent(sort: { fields: date, order: DESC }) {
        nodes {
          _id
          title
          href
          date(formatString: "D MMM YYYY")
          image {
            asset {
              _id
              gatsbyImageData(placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  `);

  const newsItems = data.allSanityNewsItem.nodes;
  const events = data.allSanityGroupEvent.nodes;
  // find day after event
  events.forEach(function (event) {
    const eventDate = new Date(event.date);
    const eventOver = new Date(eventDate);
    eventOver.setDate(eventDate.getDate() + 1);
    event.nextDay = Number(eventOver);
  });

  return (
    <Layout
      title="News"
      description="News and Announcements from The Design Group"
    >
      {newsItems.map((i) => (
        <NewsItem newsItem={i} />
      ))}

      <aside>
        <div class="heading-container">
          <h2>Recent events</h2>
          <div class="heading-line"></div>
        </div>

        <div class="recent-events-container">
          {events
            // display only the most recent three events in the past
            .filter((event) => new Date(event.nextDay) < new Date())
            .slice(0, 3)
            .map((event) => (
              <article className="recent-event" key={event._id}>
                <a href={event.href}>
                  {
                    //Check for image
                    event.image !== null && event.image.asset ? (
                      <GatsbyImage
                        image={event.image.asset.gatsbyImageData}
                        alt=""
                      />
                    ) : null
                  }

                  {event.title}
                </a>
              </article>
            ))}
        </div>
        <p>
          <Link to="../past-events/">View all past events</Link>
        </p>
      </aside>
    </Layout>
  );
}
